.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .top {
    flex: 0;
    border-bottom: solid 1px var(--adm-color-border);
  }
  
  .body {
    flex: 1;
    display: flex;
    /* justify-content: center;
    align-items: center; */
  }
  
  .bottom {
    flex: 0;
    border-top: solid 1px var(--adm-color-border);
    padding-bottom: 20px;
  }


  .password {
    display: flex;
    align-items: center;
    
  }

  .input {
    flex: auto;
  }
  .eye {
    flex: none;
    margin-left: 8px;
    padding: 4px;
    cursor: pointer;
    
  }

  .eye svg {
    display: block;
    font-size: var(--adm-font-size-7);
  }

  /* :root:root {
    --adm-color-primary: #a062d4;
  } */


  .imagesContainer {
    --adm-image-width: 90px;
    --adm-image-height: 60px;
  }

  .adm-image-img {
    /* width: 80px; */
    width: auto;
    height: 80px;
  }

  .footer {
    padding: 16px;
    text-align: center;
  }
  
  .footerButton {
    font-size: var(--adm-font-size-4);
    color: #ffffff;
    line-height: 1;
    padding: 10px 16px;
    background-color: rgba(153, 153, 153, 0.4);
    border-radius: 100px;
    display: inline-block;
    cursor: pointer;
  }